import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import parse from "html-react-parser";
import Image from "gatsby-image";

import "./index.scss";
import { sendClickEvent } from "../../helpers/garfield";
import { TECH_BLOG_ARTICLE_TILE } from "../../data/techBlogEventConstants";

const BlogCard = ({
  postData,
  defaultImage,
  hideExcerpt = false,
}) => {
  const featuredImage = {
    fluid:
      postData.featuredImage?.node?.localFile
        ?.childImageSharp?.fluid,
    alt: postData.featuredImage?.node?.alt || ``,
  };

  return (
    <Link
      to={postData.uri}
      className="blog-grid-card-container"
      onClick={() =>
        sendClickEvent(
          TECH_BLOG_ARTICLE_TILE,
          postData.title
        )
      }
    >
      <div className="blog-grid-img-container">
        {featuredImage?.fluid ? (
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
          />
        ) : (
          <Image
            fluid={defaultImage?.childImageSharp?.fluid}
          />
        )}
      </div>
      <div className="blog-grid-content-container">
        <h4>{postData?.category || "<Category>"}</h4>
        <h3>{postData?.title || "<Title>"}</h3>
        {hideExcerpt ?? (
          <div className="excerpt">
            {parse(postData?.excerpt) || "<Excerpt>"}
          </div>
        )}
        <div className="blog-detail-section">
          <span>{postData?.subtitleAcf?.text || ""}</span>
          {postData.date ? (
            <span>{postData.date}</span>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

BlogCard.propTypes = {
  postData: PropTypes.object.isRequired,
  defaultImage: PropTypes.object,
  showAuthor: PropTypes.bool,
  hideExcerpt: PropTypes.bool,
};

export default BlogCard;
