export const TECH_BLOG_ARTICLE_TILE =
  "tech_blog_article_tile";

export const TECH_BLOG_CATEGORY_NAV_BAR =
  "tech_blog_category_nav_bar";

export const TECH_BLOG_CATEGORY_DROP_DOWN =
  "tech_blog_category_drop_down";

export const TECH_BLOG_POST_FB_SHARE =
  "tech_blog_post_fb_share";

export const TECH_BLOG_POST_LI_SHARE =
  "tech_blog_post_li_share";

export const TECH_BLOG_POST_X_SHARE =
  "tech_blog_post_x_share";

export const TECH_BLOG_POST_URL_COPY =
  "tech_blog_post_url_copy";
