export const flattenBlogData = data => {
  return data.map(blog => ({
    ...blog,
    authorData: blog?.author?.node,
    author: blog?.author?.node?.name,
    category: blog?.categories?.nodes?.[0]?.name,
    categorySlug: decodeURI(
      trimCatName(blog?.categories?.nodes?.[0]?.slug)
    ),
    rawCategorySlug: blog?.categories?.nodes?.[0]?.slug,
    slug: decodeURI(blog?.slug),
    langCode: blog?.language?.locale,
    langSlug: blog?.language?.slug,
  }));
};

export const trimCatName = catSlug =>
  catSlug?.split("-")?.slice(0, -1)?.join("-");

export const getCategoryFromUrlPathName = pathname => {
  return pathname.split("/")[2];
};

export const removeBlogsForId = (blogNodes, blogId) => {
  return blogNodes.filter(blog => blog.id != blogId);
};